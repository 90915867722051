.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  padding-bottom: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.privacycheckbox {
  display: inline-block;
  vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.left-border {
  border: none;
  border-left: solid 1px #c9c9c9;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
}

.heading {
  font-size: 2.5rem;
  font-weight: 100;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  padding-left: 0.75rem;
}

.avatar_title {
  line-height: 3rem;
  display: inline-block;
  vertical-align: middle;
}

.personcard {
  min-height: 12rem;
}

code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: #eee;
  border-radius: 6px;
}

.display-inline {
  display: inline;
}